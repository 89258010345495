import React, { useCallback, useEffect, useMemo, useState, useContext } from 'react'
import { Alert, Button, Col, Divider, message, Modal, Row, Spin, Table, Typography } from 'antd'
import Approval from '../../Approval/Approval'
import PartnerDocuments from '../Sections/PartnerDocuments'
import EditableTable from '../../../Common/EditableTable/EditableTable'
import { errors, profileScreen } from '../../../../translates'
import {
  useMutateAdminPartnerAcceptEditInfoRequest,
  useMutateAdminPartnerDeclineEditInfoRequest
} from '../../../../api/react-query/adminPartners'
import { ACCEPT, ACCEPTED, DELETED, NONE } from '../../../../Constants'
import {
  arraySortByArray,
  createApiDataFromFormWithFiles,
  flattenObject,
  getOnlyInputFieldInPreparedData,
  getPassportIssueDateRules,
  isEqualRegistrationKindCodes,
  restoreFlattenObject,
  validateBankAccountIndividual,
  validateBankAccountSelfEmployed,
  validateFullInn
} from '../../../../helper'
import { apiErrorCodes, AppConfig, individual, selfEmployed } from '../../../../AppConfig'
import { ConfigContext } from '../../../../context/configContext'
import RegistrationKindItem from './RegistrationKindItem'

const { Text } = Typography
const columns = [
  {
    title: 'Название поля',
    dataIndex: 'fieldName'
  },
  {
    title: 'Текущее значение',
    dataIndex: 'oldValue',
    width: '35%'
  },
  {
    title: 'Новое значение',
    dataIndex: 'newValue',
    editable: true,
    width: '35%'
  }
]

function EditProfileInfoRequest ({ profile, setPartner, disabled, partnerId, viewOnly = false }) {
  const { setConfig, user, portalButtonState, isCoordinator, isExternalCoordinator } = useContext(ConfigContext)
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [profileData, setProfileData] = useState([])
  const [backendFieldsErrors, setBackendFieldsErrors] = useState({})
  const [fieldsHasErrors, setFieldsHasErrors] = useState([])
  const [closeModalAfterAccept, setCloseModalAfterAccept] = useState(false)

  const isSzContent = useMemo(() => {
    return profile?.personalData?.roleCode === AppConfig.partnerRoles.szContent
  }, [profile?.personalData?.roleCode])

  const isResearcher = useMemo(() => {
    return profile?.personalData?.roleCode === AppConfig.partnerRoles.researcher
  }, [profile?.personalData?.roleCode])

  const isSzOperator = useMemo(() => {
    return profile?.personalData?.roleCode === AppConfig.partnerRoles.szOperator
  }, [profile?.personalData?.roleCode])

  const documents = useMemo(() => {
    return [
      'passportPhoto1',
      'passportPhoto2',
      'passportPhotoExtraReg',
      'passportPhotoTmpReg',
      'innPhoto',
      'bankDetailsPhoto',
      'egripPhoto',
      'trusteeAgreement',
      'trusteeConfirmation',
      'legalCapacityConfirmation',
      'trusteePassport',
      ...((isSzContent || isResearcher || isSzOperator) ? [] : ['vaccinacionPhoto'])
    ]
  }, [isSzContent, isResearcher, isSzOperator])

  const {
    mutate: acceptRequest,
    data: acceptRequestData,
    isLoading: acceptRequestLoading,
    isSuccess: acceptRequestSuccess,
    isError: acceptRequestError
  } = useMutateAdminPartnerAcceptEditInfoRequest()
  const {
    mutate: declineRequest,
    data: declineRequestData,
    isLoading: declineRequestLoading,
    isSuccess: declineRequestSuccess,
    isError: declineRequestError
  } = useMutateAdminPartnerDeclineEditInfoRequest()

  const showModal = () => {
    if (user?.online && (isCoordinator || isExternalCoordinator)) {
      portalButtonState?.stopStatusRequesting()
    }
    setIsModalVisible(true)
  }
  const handleCancel = () => {
    if (user?.online && (isCoordinator || isExternalCoordinator)) {
      portalButtonState?.startStatusRequesting()
    }
    setIsModalVisible(false)
  }

  const prepareAcceptRequestData = useCallback((newValues) => {
    newValues = restoreFlattenObject(newValues)

    const data = createApiDataFromFormWithFiles({
      personalData: Object.fromEntries(Object.entries({
        firstName: newValues.firstName,
        middleName: profile?.form?.personalDetails?.withoutMiddleName && !newValues.middleName ? '' : newValues.middleName,
        surname: newValues.surname,
        withoutMiddleName: newValues.middleName !== undefined ? !newValues.middleName : undefined,
        passport: newValues.passport,
        carLicencePlate: newValues.carLicencePlate,
        registrationAddress: Object.fromEntries(Object.entries({
          ...newValues.registrationAddress
        }).map(([key, value]) => value === null ? [key, ''] : [key, value])),
        registrationKind: profile?.form?.personalDetails?.registrationKindCode,
        isLegalAgeCapable: profile?.form?.personalDetails?.isLegalAgeCapable
      }).filter(([, value]) => value !== undefined)),
      bankDetails: Object.fromEntries(Object.entries({
        bankName: newValues.bankName,
        name: newValues.name,
        bankAccount: newValues.bankAccount,
        bankBik: newValues.bankBik
      }).filter(([, value]) => value !== undefined)),
      bankDetailsPhoto: newValues.bankDetailsPhoto,
      passportPhoto1: newValues.passportPhoto1,
      passportPhoto2: newValues.passportPhoto2,
      passportPhotoExtraReg: newValues.passportPhotoExtraReg,
      passportPhotoTmpReg: newValues.passportPhotoTmpReg,
      egripPhoto: newValues.egripPhoto,
      trusteeAgreement: newValues.trusteeAgreement,
      trusteeConfirmation: newValues.trusteeConfirmation,
      legalCapacityConfirmation: newValues.legalCapacityConfirmation,
      trusteePassport: newValues.trusteePassport,
      innPhoto: newValues.innPhoto,
      vaccinacionPhoto: newValues.vaccinacionPhoto,
      innDetails: { inn: newValues.inn }
    })

    delete data?.personalData?.birthDate

    return ({
      ...(Object.keys(data.personalData).length && { personalDetails: data.personalData }),
      ...(Object.keys(data.bankDetails).length && { bankDetails: data.bankDetails }),
      ...(Object.keys(data.innDetails).length && { innDetails: data.innDetails }),
      bankDetailsPhoto: newValues.bankDetailsPhoto,
      passportPhoto1: newValues.passportPhoto1,
      passportPhoto2: newValues.passportPhoto2,
      passportPhotoExtraReg: newValues.passportPhotoExtraReg,
      passportPhotoTmpReg: newValues.passportPhotoTmpReg,
      egripPhoto: newValues.egripPhoto,
      trusteeAgreement: newValues.trusteeAgreement,
      trusteeConfirmation: newValues.trusteeConfirmation,
      legalCapacityConfirmation: newValues.legalCapacityConfirmation,
      trusteePassport: newValues.trusteePassport,
      innPhoto: newValues.innPhoto,
      vaccinacionPhoto: newValues.vaccinacionPhoto
    })
  }, [profile])

  const handleCommit = ({ outcome, comment: reason }) => {
    setBackendFieldsErrors({})
    if (outcome === ACCEPT) {
      const newValues = Object.fromEntries(profileData.map(item => [item.key, item.newValue]))
      setCloseModalAfterAccept(true)
      acceptRequest({
        id: partnerId,
        data: prepareAcceptRequestData({
          ...newValues,
          bankDetailsPhoto: profile?.form?.bankDetailsPhoto?.fileId,
          passportPhoto1: profile?.form?.passportPhoto1?.fileId,
          passportPhoto2: profile?.form?.passportPhoto2?.fileId,
          passportPhotoExtraReg: profile?.form?.passportPhotoExtraReg?.fileId,
          passportPhotoTmpReg: profile?.form?.passportPhotoTmpReg?.fileId,
          innPhoto: profile?.form?.innPhoto?.fileId,
          egripPhoto: profile?.form?.egripPhoto?.fileId,
          trusteeAgreement: profile?.form?.trusteeAgreement?.fileId,
          trusteeConfirmation: profile?.form?.trusteeConfirmation?.fileId,
          legalCapacityConfirmation: profile?.form?.legalCapacityConfirmation?.fileId,
          trusteePassport: profile?.form?.trusteePassport?.fileId,
          vaccinacionPhoto: profile?.form?.vaccinacionPhoto?.fileId
        })
      })
    } else {
      declineRequest({ id: partnerId, reason })
    }
  }

  useEffect(() => {
    const data = []
    const flattenPersonalDataForm = flattenObject({
      ...profile?.form?.personalDetails,
      [profileScreen.input.inn.name]: profile?.form?.innDetails?.inn
    } || {})
    const flattenPersonalDataProfile = flattenObject({
      ...profile?.personalData,
      [profileScreen.input.inn.name]: profile?.innDetails?.inn
    } || {})
    const keys = arraySortByArray(Object.keys(flattenPersonalDataForm), [
      profileScreen.input.surname.name,
      profileScreen.input.firstName.name,
      profileScreen.input.middleName.name
    ])

    const notRequiredKeys = [
      profileScreen.input.middleName.name,
      `registrationAddress.${profileScreen.input.registrationAddressdistrict.name}`,
      `registrationAddress.${profileScreen.input.registrationAddresscity.name}`,
      `registrationAddress.${profileScreen.input.registrationAddresssettlement.name}`,
      `registrationAddress.${profileScreen.input.registrationAddressbuilding.name}`,
      `registrationAddress.${profileScreen.input.registrationAddressapartment.name}`
    ]

    keys?.map(key => {
      if ([
        profileScreen.input.withoutMiddleName.name,
        `${profileScreen.input.registrationKind.name}Code`,
        profileScreen.input.isLegalAgeCapable.name
      ].includes(key)) {
        return
      }
      data.push({
        key,
        required: !notRequiredKeys.includes(key),
        fieldName: profileScreen.input[key.replace('.', '')]?.label,
        oldValue: flattenPersonalDataProfile?.[key],
        newValue: flattenPersonalDataForm?.[key],
        specialValidation: [
          ...(key === profileScreen.input.inn.name
            ? [{
              message: errors.innCheckSum,
              validator: (rule, value) => validateFullInn({ rule, inn: value })
            }]
            : []
          ),
          ...(key === `passport.${profileScreen.input.passportissueDate.name}` ? getPassportIssueDateRules() : [])
        ]
      })
    })
    Object.keys(profile?.form?.bankDetails || {})?.map(key => {
      if (key === profileScreen.input.bankDetailsPhoto.name) {
        return
      }
      data.push({
        key,
        fieldName: profileScreen.input[key]?.label,
        oldValue: profile?.bankDetails?.[key],
        newValue: profile?.form?.bankDetails?.[key],
        specialValidation: [
          ...(key === profileScreen.input.bankAccount.name && profile?.personalData?.legalForm === individual
            ? [{
              message: errors.bankAccount,
              validator: validateBankAccountIndividual
            }]
            : []
          ),
          ...(key === profileScreen.input.bankAccount.name && profile?.personalData?.legalForm === selfEmployed
            ? [{
              message: errors.bankAccountSelf,
              validator: validateBankAccountSelfEmployed
            }]
            : []
          )
        ]

      })
    })
    setProfileData(data)
  }, [profile])

  useEffect(() => {
    if (acceptRequestError) {
      message.error('Не удалось принять запрос.')
    }
  }, [acceptRequestError])

  useEffect(() => {
    if (acceptRequestSuccess) {
      if (acceptRequestData?.errorMessage) {
        if (acceptRequestData.errorCode === apiErrorCodes.innDuplicate) {
          setBackendFieldsErrors({ inn: [acceptRequestData.errorMessage] })
        }
        return message.error(acceptRequestData?.errorMessage || 'Не удалось принять запрос.')
      }
      if (closeModalAfterAccept) {
        handleCancel()
        setCloseModalAfterAccept(false)
      }
      message.success('Запрос на изменение данных принят.')
      setConfig({ modalData: { isEditProfileInfoRequest: isModalVisible, refetchProfileInfo: true } })
    }
  }, [acceptRequestData, acceptRequestSuccess])

  useEffect(() => {
    if (declineRequestError) {
      message.error('Не удалось отклонить запрос.')
    }
  }, [declineRequestError])

  useEffect(() => {
    if (declineRequestSuccess) {
      if (declineRequestData?.errorMessage) {
        return message.error(declineRequestData?.errorMessage || 'Не удалось отклонить запрос.')
      }
      handleCancel()
      message.success('Запрос на изменение данных отклонен.')
    }
  }, [declineRequestData, declineRequestSuccess])

  useEffect(() => {
    setConfig({ modalData: { isEditProfileInfoRequest: isModalVisible } })
  }, [isModalVisible])

  const getRowClassName = (record) => !(!record.oldValue && !record.newValue) && record.newValue !== record.oldValue
    ? 'editable-row bg-changed'
    : 'editable-row'

  const getAcceptButton = useCallback((requestData, fieldName, profileData) => {
    return (
      <Button
        size='small' type='primary' onClick={() => {
          // Счет и БИК данные отправляются на бэк сразу вместе. Подхватим данные БИК из общего объекта профиля
          const accountName = profileScreen.input.bankAccount.name
          const bikName = profileScreen.input.bankBik.name
          const passportPhotoTmpReg = profileScreen.input.passportPhotoTmpReg.name
          const trusteeConfirmation = profileScreen.input.trusteeConfirmation.name
          const legalCapacityConfirmation = profileScreen.input.legalCapacityConfirmation.name
          let data = getOnlyInputFieldInPreparedData(requestData, prepareAcceptRequestData(requestData))
          data = {
            ...data,
            ...(fieldName === accountName && profileData && {
              bankDetails: {
                ...data.bankDetails,
                [bikName]: profileData.filter(item => item.key === bikName)[0].newValue
              }
            }),
            ...(fieldName === passportPhotoTmpReg && profileData && {
              personalDetails: {
                ...data.personalDetails,
                registrationKind: profileData.personalDetails?.registrationKindCode
              }
            }),
            ...([trusteeConfirmation, legalCapacityConfirmation].includes(fieldName) && profileData && {
              personalDetails: {
                ...data.personalDetails,
                isLegalAgeCapable: profileData.personalDetails?.isLegalAgeCapable
              }
            })
          }
          acceptRequest({ id: partnerId, data })
        }}
        disabled={fieldsHasErrors?.includes(fieldName)}
      >
        Принять
      </Button>
    )
  }, [fieldsHasErrors])

  const tableProps = useMemo(() => ({
    backendFieldsErrors,
    setHasErrors: setFieldsHasErrors,
    columns: [...columns,
      ...!viewOnly ? [{
        title: '',
        dataIndex: 'action',
        width: 105,
        render: (value, record) => {
          return (!(!record.oldValue && !record.newValue) && record.oldValue !== record.newValue) || record?.isEdited
            ? getAcceptButton(
              restoreFlattenObject({ [record.key]: record.newValue }),
              record.key,
              profileData
            )
            : <>&nbsp;</>
        }
      }] : []],
    dataSource: profileData,
    setDataSource: setProfileData,
    pagination: false,
    rowClassName: getRowClassName
  }), [acceptRequest, backendFieldsErrors, partnerId, prepareAcceptRequestData, profileData, getAcceptButton])

  const profilePhotos = useMemo(() => {
    const list = documents.reduce((photos, doc) => {
      if (profile?.[doc]?.visible) {
        return {
          ...photos,
          ...(
            profile?.form?.[doc]?.fileId
              ? { [doc]: profile?.form?.[doc] }
              : { [doc]: profile?.[doc] }
          )
        }
      }
      return photos
    }, {})
    return list
  }, [profile, documents])

  const getDocumentStatus = useCallback((doc) => {
    return profile?.[doc]?.fileId && !profile?.form?.[doc]?.fileId
      ? DELETED
      : profile?.[doc]?.fileId === profile?.form?.[doc]?.fileId
        ? ACCEPTED
        : NONE
  }, [profile])

  const getDocumentButton = useCallback((doc) => {
    return viewOnly || profile?.[doc]?.fileId === profile?.form?.[doc]?.fileId
      ? null
      : profile?.[doc]?.fileId && !profile?.form?.[doc]?.fileId
        ? <Text type='danger'>Удален</Text>
        : getAcceptButton(
          { [doc]: profile?.form?.[doc]?.fileId },
          doc,
          profile?.form
        )
  }, [viewOnly, profile, getAcceptButton])

  const registrationKindButton = useMemo(() => {
    return viewOnly || isEqualRegistrationKindCodes(profile?.personalData?.registrationKindCode, profile?.form?.personalDetails?.registrationKindCode)
      ? null
      : getAcceptButton(
        { personalData: { registrationKind: profile?.form?.personalDetails?.registrationKindCode } },
        profileScreen.input.passportPhotoTmpReg.name,
        profile?.form
      )
  }, [viewOnly, profile, getAcceptButton])

  const isLoadingForm = useMemo(() => {
    return !profile?.form || acceptRequestLoading || declineRequestLoading
  }, [profile?.form, acceptRequestLoading, declineRequestLoading])

  return (
    <div>
      <Button
        ghost
        type='primary'
        className='w-150'
        disabled={disabled}
        onClick={showModal}
      >
        {viewOnly ? 'Посмотреть' : 'Проверить'}
      </Button>
      <Modal
        width={1200}
        style={{ top: 20 }}
        title={
          <Row gutter={[12, 6]} align='middle'>
            <Col className='mr-3'><b>Корректировка анкеты партнера</b></Col>
          </Row>
        }
        visible={isModalVisible}
        footer={null}
        onCancel={handleCancel}
        className='assetsModalForm'
      >
        <Row className='mb-3'>
          <Text underline>Тип занятости: <b>{profile?.personalData?.legalForm}</b></Text>
        </Row>
        <Spin spinning={isLoadingForm}>
          {profile?.registrationFlow && !(isSzContent || isResearcher || isSzOperator) && (
            <RegistrationKindItem
              form={profile?.form}
              acceptButton={registrationKindButton}
            />
          )}
          {
            profile?.form?.statusReason &&
              <Alert
                className='mb-3'
                message={<Text type='secondary'>Причины отказа</Text>}
                description={profile?.form?.statusReason}
                type='error'
              />
          }
          {
            viewOnly ? <Table {...tableProps} /> : <EditableTable {...tableProps} />
          }
          <Divider style={{ border: 0 }} />
          <PartnerDocuments
            partnerId={partnerId}
            documents={documents}
            setPartner={setPartner}
            profile={{ ...profile?.form, ...profile?.form?.bankDetails, ...profilePhotos }}
            getDocumentStatus={getDocumentStatus}
            getDocumentButton={getDocumentButton}
            correctForm={AppConfig.updateDocFormNames.form}
          />
          <Divider />
          {
            viewOnly
              ? <Button type='primary' onClick={handleCancel}>Закрыть</Button>
              : (
                <Approval
                  onCommit={handleCommit}
                  onCancel={handleCancel}
                  multiline
                  multilineRows={2}
                  disabled={fieldsHasErrors?.length}
                  autocomplete
                  targetType={AppConfig.reasonTargets.partnerForm}
                />
              )
          }
        </Spin>
      </Modal>
    </div>
  )
}

export default EditProfileInfoRequest
